import { Optional } from '@lib/interfaces/common.interface';
import { SortDirection } from '@lib/modules/table/components';

export enum UserTitle {
    MR = 'MR',
    MRS = 'MRS',
    MISS = 'MISS',
}

export enum Gender {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
}

export enum EmploymentType {
    SALARIED = 'SALARIED',
    SELF_EMPLOYED = 'SELF_EMPLOYED',
    RETIRED = 'RETIRED',
    NOT_EMPLOYED = 'NOT_EMPLOYED',
    BUSINESS_OWNER = 'BUSINESS_OWNER',
    STUDENT = 'STUDENT',
    HOMEMAKER = 'HOMEMAKER',
    OTHER = 'OTHER',
}

export enum IndustryType {
    I01 = 'I01',
    I02 = 'I02',
    I03 = 'I03',
    I04 = 'I04',
    I05 = 'I05',
    I06 = 'I06',
    I07 = 'I07',
    I08 = 'I08',
    I09 = 'I09',
    I11 = 'I11',
    I12 = 'I12',
    I13 = 'I13',
    I14 = 'I14',
    I15 = 'I15',
    I16 = 'I16',
    I17 = 'I17',
    I18 = 'I18',
    I19 = 'I19',
    I20 = 'I20',
    I21 = 'I21',
    I22 = 'I22',
    I23 = 'I23',
    I24 = 'I24',
    I25 = 'I25',
    I26 = 'I26',
    I27 = 'I27',
    I28 = 'I28',
    I29 = 'I29',
    I30 = 'I30',
    I00 = 'I00',
}

export enum RelationshipWithParent {
    SON = 'SON',
    DAUGHTER = 'DAUGHTER',
    NEPHEW = 'NEPHEW',
    NIECE = 'NIECE',
    HUSBAND = 'HUSBAND',
    WIFE = 'WIFE',
    FATHER = 'FATHER',
    MOTHER = 'MOTHER',
    EMPLOYEE = 'EMPLOYEE',
    OTHER = 'OTHER',
}

export enum UserType {
    INDIVIDUAL = 'INDIVIDUAL',
    BUSINESS = 'BUSINESS',
    DIRECTOR = 'DIRECTOR',
    SIGNATURE = 'SIGNATURE',
    SHAREHOLDER = 'SHAREHOLDER',
    SHAREHOLDER_BUSINESS = 'SHAREHOLDER_BUSINESS',
    AGENT = 'AGENT',
}

export enum UserStatus {
    PENDINGKYC = 'PENDINGKYC',
    PENDINGKYB = 'PENDINGKYB',
    ACTIVE = 'ACTIVE',
    SUSPENDED = 'SUSPENDED',
    TERMINATED = 'TERMINATED',
    PENDINGIDVERIFICATION = 'PENDINGIDVERIFICATION',
}

export enum UserStatusReason {
    I0 = 'I0',
    A0 = 'A0',
    A1 = 'A1',
    A2 = 'A2',
    A3 = 'A3',
    A4 = 'A4',
    A5 = 'A5',
    A6 = 'A6',
    A7 = 'A7',
    A8 = 'A8',
    B0 = 'B0',
    B1 = 'B1',
    B2 = 'B2',
    B3 = 'B3',
    B4 = 'B4',
    B5 = 'B5',
    B6 = 'B6',
    B7 = 'B7',
    B8 = 'B8',
    B9 = 'B9',
    B10 = 'B10',
    B11 = 'B11',
    B2E = 'B2E',
    B0E = 'B0E',
    B9E = 'B9E',
    B10E = 'B10E',
    B11E = 'B11E',
    B12 = 'B12',
    B19 = 'B19',
    S0 = 'S0',
    S1 = 'S1',
    S2 = 'S2',
    S3 = 'S3',
    S4 = 'S4',
    S5 = 'S5',
    S6 = 'S6',
    S7 = 'S7',
    S9 = 'S9',
    T0 = 'T0',
    T1 = 'T1',
    T2 = 'T2',
    T3 = 'T3',
    T4 = 'T4',
    T5 = 'T5',
    T6 = 'T6',
    T7 = 'T7',
    T8 = 'T8',
    T9 = 'T9',
    T10 = 'T10',
    T11 = 'T11',
    T12 = 'T12',
    T13 = 'T13',
    T12E = 'T12E',
    T13E = 'T13E',
    O1 = 'O1',
}

export enum SupportedKycPolicies {
    UAE_INDIVIDUAL_PRIMARY = 'UAE_INDIVIDUAL_PRIMARY',
    UAE_INDIVIDUAL_SECONDARY = 'UAE_INDIVIDUAL_SECONDARY',
}

export enum IncorporationType {
    LTD = 'LTD',
    LLC = 'LLC',
    FZE = 'FZE',
    FZCO = 'FZCO',
    FZ_LLC = 'FZ-LLC',
    INC = 'INC',
    FOUNDATION = 'FOUNDATION',
    TRUST = 'TRUST',
    FUND = 'FUND',
    PUBLIC_TRADED = 'PUBLIC_TRADED',
    GOVT_OWNED = 'GOVT_OWNED',
    LLP = 'LLP',
    OTHER = 'OTHER',
}

export enum IdentificationType {
    PASSPORT = 'PASSPORT',
    VISA = 'VISA',
    NATIONAL_ID = 'NATIONAL_ID',
    TRADE_LICENSE = 'TRADE_LICENSE',
    COMMERCIAL_LICENSE = 'COMMERCIAL_LICENSE',
    INCORPORATION_CERT = 'INCORPORATION_CERT',
    INCUMBENCY_CERT = 'INCUMBENCY_CERT',
    MEMORANDUM_ARTICLES = 'MEMORANDUM_ARTICLES',
    BANK_STATEMENT = 'BANK_STATEMENT',
    ADDITIONAL = 'ADDITIONAL',
}

export enum IdentificationDocumentType {
    NATIONAL_ID_FRONT = 'NATIONAL_ID_FRONT',
    NATIONAL_ID_BACK = 'NATIONAL_ID_BACK',
    PASSPORT_PAGE1 = 'PASSPORT_PAGE1',
    PASSPORT_PAGE2 = 'PASSPORT_PAGE2',
    VISA = 'VISA',
    ADDITIONAL_PAGE_1 = 'ADDITIONAL_PAGE_1',
    ADDITIONAL_PAGE_2 = 'ADDITIONAL_PAGE_2',
}

export enum IdentificationStatus {
    PENDINGKYB = 'PENDINGKYB',
}

export enum IdentificationStatusReasonCode {
    I0 = 'I0',
}

export interface ICustomer {
    id: string;
    user_id: string;
    title: UserTitle;
    first_name: string;
    middle_name: string;
    last_name: string;
    native_name: string;
    full_legal_name: string;
    full_name_local_language: string;
    mother_name: string;
    date_of_birth: string;
    email: string;
    mobile: string;
    address: IAddress;
    gender: Gender;
    preferred_language: string;
    place_of_birth: string;
    place_of_birth_city: string;
    nationality: string;
    parent_user_id: string;
    created: string;
    modified: string;
    country_residence: string;
    country_tax_residence: string;
    education_level: string;
    employment_type: EmploymentType;
    employment_title: string;
    monthly_salary: number;
    company_name: string;
    company_address: IAddress;
    company_country: string;
    industry_type: IndustryType;
    expected_monthly_balance: number;
    relationship_with_parent: RelationshipWithParent;
    user_type: UserType;
    business_legal_name: string;
    business_dba_name: string;
    date_established: string;
    phone_number: string;
    website: string;
    status: UserStatus;
    status_reason_code: UserStatusReason;
    kyc_policy_passed: SupportedKycPolicies;
    designation: string;
    shares_percentage: number;
    share_controller_name: string;
    local_address: IAddress;
    incorporation_type: IncorporationType;
    entity_registered_no: string;
    old_entity_name: string;
    old_entity_ops_start_date: string;
    old_entity_ops_end_date: string;
    regulated_entity: boolean;
    regulator_name: string;
    business_activities: string;
    other_operated_countries: string;
    tax_residence_country: string;
    tin: string;
    external_auditor_name: string;
    director_list: Array<string>;
    signature_list: Array<string>;
    shareholder_list: Array<string>;
    company_onbehalf: boolean;
    company_onbehalf_relation: string;
    group_additional_info: ICustomerGroupAdditionalInfo;
    pep_check_info: Optional<IUserPepCheckInfo>;
    no_of_employees: number;
    accept_cash_check: boolean;
    annual_turnover: number;
    source_of_funds: string;
    source_fund_uae: boolean;
    due_diligence_declaration: IUserDueDiligenceDeclaration;
    license_type: string;
    license_expiry: string;
    currency: string;
    modified_by: string;
    custom_info: Optional<{
        custom: string;
    }>;
}

export interface IAddress {
    address_line1: string;
    address_line2: string;
    city: string;
    state: string;
    postal_code: string;
    country: string;
}

export interface ICustomerStatusPopup {
    id: string;
    user_status: string;
    status: UserStatus;
    first_name: string;
    middle_name: string;
    last_name: string;
    native_name: string;
    full_legal_name: string;
    created: string;
    change_status_value?: string;
}

export interface ICustomerGroupAdditionalInfo {
    group_entity: Optional<boolean>;
    independent_entity: Optional<boolean>;
    special_investment: Optional<boolean>;
    entity_details: Optional<string>;
}

export interface IUserPepCheckInfo {
    pri_pep_check: Optional<boolean>;
    pri_pep_check_name: Optional<string>;
    sec_pep_check: Optional<boolean>;
    sec_pep_check_name: Optional<string>;
}

export interface IUserDueDiligenceDeclaration {
    bankruptcy_declaration: Optional<boolean>;
    disciplinary_declaration: Optional<boolean>;
    fraud_aml_declaration: Optional<boolean>;
    disqualification_declaration: Optional<boolean>;
    conviction_accusition_declaration: Optional<boolean>;
    breach_of_law_declaration: Optional<boolean>;
}

export interface ICustomerIdentification {
    id: string;
    type: IdentificationType;
    title: string;
    user_id: string;
    number: string;
    issuance_authority: string;
    place_of_issuance: string;
    issuance_date: string;
    expiration_date: string;
    document_status: IdentificationStatus;
    document_status_reason: IdentificationStatusReasonCode;
    files: Array<ICustomerIdentificationFile>;
    created: string;
    modified: string;
    identification_note: string;
}

export interface ICustomerAnalyticsData {
    percentage: number;
    direction: 'up' | 'down';
}

export interface ICustomerIdentificationFile {
    id: string;
    file_name: string;
    content_type: string;
    size: number;
    identification_type: IdentificationType;
    identification_document_type: IdentificationDocumentType;
    version: number;
    created: string;
    title: string;
}

export interface ICustomerIdentificationFlattenedFile extends ICustomerIdentificationFile {
    identification: ICustomerIdentification;
}

export interface ICustomerIdentificationFileWithMetadata extends ICustomerIdentificationFlattenedFile {
    file: Blob;
    fileBase64?: string;
}

export interface ICustomerAnalyticsApiResponse {
    account: ICustomerAnalyticsData;
    cards: ICustomerAnalyticsData;
    user: ICustomerAnalyticsData;
}

export interface IChangeCustomerStatusApiRequest {
    status: UserStatus;
    status_reason_code: UserStatusReason;
}

export interface ICustomerCountApiResponse {
    total_customers: string;
    total_cards: string;
    total_accounts: string;
    details: ICustomerCountDetails;
}

export interface ICustomerCountDetails {
    customer_data: ICustomerCountData[];
    card_data: ICustomerCountCardData[];
    account_data: ICustomerCountAccountData[];
}

export interface ICustomerCountData {
    value: string;
    active: {
        count: number;
    };
    suspended: {
        count: number;
    };
    terminated: {
        count: number;
    };
    pending_kyc: {
        count: number;
    };
    pending_idv: {
        count: number;
    };
    new: {
        count: number;
    };
}

export interface ICustomerCountCardData {
    value: string;
    active: {
        count: number;
    };
    suspended: {
        count: number;
    };
    terminated: {
        count: number;
    };
    inactive?: {
        count: number;
    };
    prepaid?: {
        count: number;
    };
    credit?: {
        count: number;
    };
    cycle?: string;
    data?: {
        date: string;
        count: number;
    }[];
}

export interface ICustomerCountAccountData {
    value: string;
    active: {
        count: number;
    };
    suspended: {
        count: number;
    };
    terminated: {
        count: number;
    };
    cycle?: string;
    data?: {
        date: string;
        count: number;
    }[];
}

export interface ICustomerSearchRequest {
    id: string;
    name: string;
    alias: string;
    mobile: string;
    mobileNumber: string;
    email: string;
    status: Array<UserStatus>;
    user_type: UserType;
    business_legal_name: string;
    business_dba_name: string;
    website: string;
    created_from: string;
    created_to: string;
    modified_from: string;
    modified_to: string;
    bin: string;
    last_four: string;
    limit: number;
    after: string;
    sort_by: string;
    order_by: SortDirection;
}

export interface ICreateCustomer {
    nationality: string;
    date_of_birth: string;
    first_name: string;
    last_name: string;
    mother_name: string;
    marital_status: string;
    gender: string;
    email: string;
    education_level: string;
    employment_type: string;
    preferred_language: string;
    full_name_local_language: string;
    mobile: string;
    address: IAddress;
    custom_info: Record<string, any>;
}

export interface IIdentificationDocument {
    id?: string;
    type: IdentificationType;
    title?: string;
    number: string;
    issuance_authority?: string;
    place_of_issuance?: string;
    expiration_date?: string;
    issuance_date?: string;
    identification_note?: string;
}

export interface sdkToken {
    applicantId: string;
    token: string;
}
